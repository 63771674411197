"use client";
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig, b2cMsalConfig } from "authConfig";


export const MsalInstanceFactory = (isB2C: boolean = false) => {
    const msalInstance = isB2C ? new PublicClientApplication(b2cMsalConfig) : new PublicClientApplication(msalConfig);

    msalInstance.initialize().then(() => {
        // Account selection logic is app dependent. Adjust as needed for different use cases.
        const accounts = msalInstance.getAllAccounts();

        if (!msalInstance.getActiveAccount() && accounts.length > 0) {
            msalInstance.setActiveAccount(accounts[0]);
        }

        const callbackId = msalInstance.addEventCallback((event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                msalInstance.setActiveAccount(account);
            }
        });

        return () => {
            if (callbackId) {
                msalInstance.removeEventCallback(callbackId);
            }
        };
    });
    return msalInstance;
};