"use client";

import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { useRouter } from "next/navigation";
import { CustomNavigationClient } from "@utils/navigationClient";
import { MsalInstanceFactory } from "./Msal";
import { LayoutProps } from "@lib/definitions";
const MsalWrapperB2C = ({
  children
}: LayoutProps) => {
  const msalInstance = MsalInstanceFactory(true);
  msalInstance.setNavigationClient(new CustomNavigationClient(useRouter()));
  return <MsalProvider instance={msalInstance} data-sentry-element="MsalProvider" data-sentry-component="MsalWrapperB2C" data-sentry-source-file="MsalWrapperB2C.tsx">{children}</MsalProvider>;
};
export default MsalWrapperB2C;