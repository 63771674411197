"use client";

import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import NextAppDirEmotionCacheProvider from './EmotionCache';
import theme from './theme';
import '@/styles/globals.css';
export default function ThemeRegistry({
  children
}: Readonly<{
  children: React.ReactNode;
}>) {
  return <NextAppDirEmotionCacheProvider options={{
    key: 'mui'
  }} data-sentry-element="NextAppDirEmotionCacheProvider" data-sentry-component="ThemeRegistry" data-sentry-source-file="ThemeRegistry.tsx">
      <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-source-file="ThemeRegistry.tsx">
        <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="ThemeRegistry.tsx" />
        {children}
      </ThemeProvider>
    </NextAppDirEmotionCacheProvider>;
}