'use client';

import MsalWrapper from "@components/MsalWrapper";
import MsalWrapperB2C from "@components/MsalWrapperB2C";
import ThemeRegistry from "@components/ThemeRegistry/ThemeRegistry";
import { LayoutProps } from "@lib/definitions";
import { isB2cUserLoggingIn } from "@utils/helpers";
import { useEffect, useState } from "react";
export default function RootLayout({
  children
}: Readonly<LayoutProps>) {
  const [isB2cUser, setIsB2cUser] = useState(false);
  useEffect(() => {
    setIsB2cUser(isB2cUserLoggingIn());
  }, []);
  return <html lang="en" data-sentry-component="RootLayout" data-sentry-source-file="layout.tsx">
      <head>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <title>Card Compliant Portal</title>
      </head>
      <body>

        <ThemeRegistry data-sentry-element="ThemeRegistry" data-sentry-source-file="layout.tsx">
          {isB2cUser && <MsalWrapperB2C>
              {children}
            </MsalWrapperB2C>}
          {!isB2cUser && <MsalWrapper>
              {children}
            </MsalWrapper>}
        </ThemeRegistry>
      </body>
    </html>;
}